var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import themedComponent from '../../../../common/components/hoc/themedComponent';
import Icon from '../../../../common/components/Icon';
var NEW_ICON_SIZE = 24;
var iconStyles = {
    fill: 'sys.neutral.icon.default',
    role: 'presentation',
    size: NEW_ICON_SIZE,
};
export var Account = themedComponent(function () { return React.createElement(Icon, __assign({ name: "accountH24Large" }, iconStyles)); }, function () { return React.createElement(Icon, __assign({ name: "accountFfh" }, iconStyles)); });
export var Wishlist = function (props) { return React.createElement(Icon, __assign({ name: "wishlistV2Large" }, iconStyles, props)); };
export var Cart = themedComponent(function () { return React.createElement(Icon, __assign({ name: "cartH24V2Large" }, iconStyles)); }, function () { return React.createElement(Icon, __assign({ name: "cartFfh" }, iconStyles)); });
