var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { display, themeGet } from 'styled-system';
import { Flex } from '../../../common/components/Flex';
import { themeMinHeight } from '../../../common/theme/system-utilities';
import { TrackingEventName, TrackingPlacement } from '../types';
import Link from './common/Link';
import Text from './common/Text';
import EntryIcon from './EntryIcon';
var Item = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n\n  &:last-of-type {\n    margin-right: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n\n  &:last-of-type {\n    margin-right: ", ";\n  }\n"])), display, themeMinHeight, themeGet('space.sp_0'));
var itemStyles = {
    mr: 'sp_32',
    flexDirection: 'row',
    alignItems: 'center',
    themeMinHeight: 'sp_32',
};
var Entrypoint = function (_a) {
    var _b = _a.icon, url = _b.url, alt = _b.alt, label = _a.label, _c = _a.labelUrl, fullUrl = _c.fullUrl, target = _c.target, restProps = __rest(_a, ["icon", "label", "labelUrl"]);
    return (React.createElement(Item, __assign({ "data-testid": "entrypoint" }, itemStyles, restProps), fullUrl ? (React.createElement(Link, { href: fullUrl, target: target, tracking: {
            eventName: TrackingEventName.Click,
            clickedText: alt !== null && alt !== void 0 ? alt : label,
            placement: TrackingPlacement.UspBar,
        } },
        React.createElement(EntryIcon, { label: label, url: url, alt: alt }),
        label)) : (React.createElement(Text, null,
        React.createElement(EntryIcon, { label: label, url: url, alt: alt }),
        label))));
};
export default Entrypoint;
var templateObject_1;
