import { UserGender } from 'graphql-ts-types';
import { TitlePrefix } from '../enums/form';
import { Region } from '../enums/Region';
var VALIDATION_TRANSLATION_PREFIX = 'h24_validation_error_form_';
export var getValidationErrorKey = function (fieldName, validatorName) { return "".concat(VALIDATION_TRANSLATION_PREFIX).concat(fieldName, "_").concat(validatorName); };
export var REGEX_NO_DIGITS = /^([^0-9]*)$/;
export var REGEX_ONLY_DIGITS = /^[0-9]*$/;
export var REGEX_ONLY_LETTERS_AND_DIGITS = /^[0-9A-Za-z]*$/;
export var REGEX_LETTERS_DOTS_AND_COMMAS = /^[a-z\u0080-\uFFFF\-. ]*$/i;
export var REGEX_LETTERS_DOTS_AND_COMMAS_ONE_LETTER = /^\s*(?:[a-zA-ZøöòóàåäüßáâäèéêëîïìôœùûüÿçÖÄÜẞÀÁÂÄÅÈÉÊËÎÏÌÔØÒÓŒÙÛÜŸÇąčęėįšųūžĄČĘĖĮŠŲŪŽ]+['\/\s.\-`]+?)*[a-zA-ZøöòóàäåüßáâäèéêëîïìôœùûüÿçÖÄÜẞÀÁÂÄÅÈÉÊËÎÏÌÔØÒÓŒÙÛÜŸÇąčęėįšųūžĄČĘĖĮŠŲŪŽ]+.?\s*$/;
export var REGEX_STREET_AND_NUMBER = /[0-9A-Za-z-+'`’)(_,.&/\"øöòóàåäüßáâäèéêëîïìôœùûüÿçÖÄÜẞÀÁÂÄÅÈÉÊËÎÏÌÔØÒÓŒÙÛÜŸÇąčęėįšųūžĄČĘĖĮŠŲŪŽ\s]+/;
export var REGEX_COMPANY = /^[0-9A-Za-z-+'`’)(_,.&@/\"øöòóåàäüßáâäèéêëîïìôœùûüÿçÖÄÜẞÀÁÂÄÅÈÉÊËÎÏÌÔØÒÓŒÙÛÜŸÇąčęėįšųūžĄČĘĖĮŠŲŪŽ\s]*$/;
export var REGEX_TAX_ID = /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST](0[1-9LMNPQRSTUV]|1[0-9LMNPQRSTUV]|2[0-9LMNPQRSTUV]|3[01LMNPQRSTUV]|4[1-9LMNPQRSTUV]|5[0-9LMNPQRSTUV]|6[0-9LMNPQRSTUV]|7[01LMNPQRSTUV]|[LMNPQRSTUV]{2})[A-Z][0-9LMNPQRSTUV]{3}[A-Z]$/;
export var REGEX_CITY = /^[0-9A-Za-z-+'`’)(_,.&/\"øöòóåàäüßáâäèéêëîïìôœùûüÿçÖÄÜẞÀÁÂÄÅÈÉÊËÎÏÌÔØÒÓŒÙÛÜŸÇąčęėįšųūžĄČĘĖĮŠŲŪŽ\s]+$/;
export var REGEX_FIRST_DIGIT_POSITIVE = /^[1-9][0-9]{3}$/;
export var REGEX_NO_WHITESPACES = /^\S*$/;
export var REGEX_START_WITH_LETTER = /^[A-Za-z].*$/;
export var REGEX_4_DIGITS_1_SPACE_2_LETTERS = /^[\d]{4}[\s]{1}[A-Z]{2}$/;
export var REGEX_EMAIL_ADDRESS = /^[a-z0-9_-]+([.|+][a-z0-9_-]+)*[@][a-z0-9-]+([.][a-z0-9-]+)*[.]([a-z]{2,}|xn--[a-z0-9]+)$/i;
export var REGEX_MIN_1_DIGIT = /[\d]+/;
export var REGEX_MIN_1_LETTER = /[a-zA-Z]+/;
export var REGEX_VALID_VAT_NUMBER = /(?:^DE[0-9]{9}$)|(?:^FR([A-Z]{2}|[0-9]{2})[0-9]{9}$)|(?:^NL[0-9]{9}B[0-9]{2}$)|(?:^ATU[A-Z0-9]{8}$)|(?:^CH(?:E(?:-| )[0-9]{3}(?:\.| )[0-9]{3}(?:\.| )[0-9]{3}( MWST)?|E[0-9]{9}(?:MWST)?)$)|(?:^BE(0[0-9]{9}|[0-9]{10})$)|(?:^IT[0-9]{11}$)/;
export var REGEX_VALID_VAT_TAX_NUMBER = /(?:^[a-zA-Z0-9øöòàäüßáâäèéêëîïìôœùûüÿçÖÄÜẞÀÁÂÄÈÉÊËÎÏÌÔØÒŒÙÛÜŸÇąčęėįšųūžĄČĘĖĮŠŲŪŽ\s.-]*$)|(?:^[0-9]{4}0[0-9]{4}[0-9]{4}$)|(?:^[0-9]{4}[0-9]{4}[0-9]{4}$)|(?:^[0-9]{4}\/[0-9]{4}\/[0-9]{4}$)|(?:^[0-9]{4}\/[0-9]{3}\/[0-9]{5}$)|(?:^[0-9]{3}\/[0-9]{4}\/[0-9]{4}$)|(?:^[0-9]{3}\/[0-9]{3}\/[0-9]{5}$)|(?:^0[0-9]{2}\/[0-9]{3}\/[0-9]{5}$)|(?:^[0-9]{2}\/[0-9]{3}\/[0-9]{5}$)/;
// Match order number (10 digits starting with 1), followed by optional dash and two letters (for marketplace orders)
export var REGEX_ORDER_NUMBER = /^1[0-9]{9}(-[A-Z]{1,2})?$/;
// Match marketplace order number (10 digits starting with 1 followed by a dash and one or two letters)
export var REGEX_MARKETPLACE_ORDER_NUMBER = /^1[0-9]{9}-[A-Z]{1,2}$/;
export var REGEX_EPAY_GIFT_CARD_CODE = /^[A-Za-z]{6}[0-9]{10}$/;
export var REGEX_BUTLERS_GIFT_CARD_CODE = /^(81|82|84)\d{13}\d?$/;
export var REGEX_GIFT_CARD_PIN = /^[0-9]{4}$/;
export var MIN_MANDATORY_CHARS = 2;
export var MAX_CHARS_NAME = 80;
// Length validation is better handled by email regex, but we keep it as 1 for
// field to be marked as required: https://home24.atlassian.net/browse/FP-2251
export var MIN_CHARS_EMAIL_ADDRESS = 1;
export var MAX_CHARS_EMAIL_ADDRESS = 160;
export var MIN_CHARS_MANDATORY_FIELD = 1;
export var MIN_CHARS_PASSWORD = 6;
export var MIN_EPAY_GIFT_CARD_CODE = 16;
export var MIN_BUTLERS_GIFT_CARD_CODE = 15;
export var MIN_GIFT_CARD_PIN = 4;
export var TAX_ID_LENGTH = 16;
export var LENGTH_POSTAL_CODE = new Map([
    [Region.De, 5],
    [Region.At, 4],
    [Region.Fr, 5],
    [Region.Nl, 7],
    [Region.Ch, 4],
    [Region.Be, 4],
    [Region.It, 5],
]);
export var DEFAULT_FORM_ERROR = 'h24_something_went_wrong';
export var GENDER_OPTIONS = [TitlePrefix.Mrs, TitlePrefix.Mr, TitlePrefix.Diverse];
export var GENDER_OPTIONS_LABELS = new Map([
    [TitlePrefix.Mrs, 'h24_mrs'],
    [TitlePrefix.Mr, 'h24_mr'],
    [TitlePrefix.Diverse, 'h24_diverse'],
]);
export var FROM_USER_GENDER_TO_TITLE_PREFIX = new Map([
    [UserGender.Female, TitlePrefix.Mrs],
    [UserGender.Male, TitlePrefix.Mr],
    [UserGender.Diverse, TitlePrefix.Diverse],
]);
export var FROM_TITLE_PREFIX_TO_USER_GENDER = new Map([
    [TitlePrefix.Mrs, UserGender.Female],
    [TitlePrefix.Mr, UserGender.Male],
    [TitlePrefix.Diverse, UserGender.Diverse],
]);
