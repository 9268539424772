var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import cookieStorage, { CookieStorage } from '../CookieStorage';
import { EventBusEvent } from '../enums/EventBus';
import { useSubscriptionCallback } from '../hooks/subscription';
import { CartUpdateStatus } from '../types/Cart';
import eventBus from './EventBus';
var EventSubscription = /** @class */ (function (_super) {
    __extends(EventSubscription, _super);
    function EventSubscription(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            value: props.defaultValue,
            emit: _this.emit.bind(_this),
        };
        _this.eventBus = eventBus;
        _this.handleEvent = _this.handleEvent.bind(_this);
        return _this;
    }
    EventSubscription.prototype.emit = function (data) {
        this.eventBus.emit(this.props.eventName, data);
    };
    EventSubscription.prototype.handleEvent = function (event) {
        var _this = this;
        this.setState(function (_a) {
            var value = _a.value;
            var newValue = _this.props.eventReducer(event);
            return value !== newValue ? { value: newValue } : null;
        });
    };
    EventSubscription.prototype.componentDidMount = function () {
        this.eventBus.on(this.props.eventName, this.handleEvent);
    };
    EventSubscription.prototype.componentWillUnmount = function () {
        this.eventBus.off(this.props.eventName, this.handleEvent);
    };
    EventSubscription.prototype.render = function () {
        return this.props.children(this.state);
    };
    EventSubscription.defaultProps = {
        defaultValue: null,
        eventReducer: function (value) { return value; },
    };
    return EventSubscription;
}(React.Component));
export var WishlistSubscription = function (_a) {
    var initialCount = _a.initialCount, children = _a.children;
    return (React.createElement(EventSubscription, { defaultValue: typeof initialCount === 'number' ? initialCount : cookieStorage.getWishlistCount(), eventName: EventBusEvent.WishlistCounterUpdate, eventReducer: function (_a) {
            var _b = _a === void 0 ? {} : _a, wishlistItemsCount = _b.wishlistItemsCount;
            return CookieStorage.normalizeNumber(wishlistItemsCount);
        } }, children));
};
export var CartSubscription = function (_a) {
    var initialCount = _a.initialCount, children = _a.children;
    var _b = __read(useState(function () {
        return typeof initialCount === 'number' ? initialCount : cookieStorage.getCartCount();
    }), 2), count = _b[0], setCount = _b[1];
    useSubscriptionCallback(EventBusEvent.CartUpdate, function (data) {
        if (data.status === CartUpdateStatus.Success) {
            setCount(data.cart.totalItems);
        }
    });
    useSubscriptionCallback(EventBusEvent.CartCounterUpdate, function (newCount) {
        setCount(newCount);
    });
    return children({ value: count });
};
export var DisclaimerSubscription = function (props) {
    var _a;
    return (React.createElement(EventSubscription, { defaultValue: { open: false }, eventName: (_a = props.toggleEvent) !== null && _a !== void 0 ? _a : EventBusEvent.ToggleDisclaimer }, props.children));
};
export default EventSubscription;
