var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { compose, height, maxHeight, maxWidth, width } from 'styled-system';
import h24LogoSvg from '../../../../common/assets/images/home-24-logo.svg';
import { H24_IMG_HEIGHT, H24_IMG_WIDTH } from '../../../../common/constants/logo';
import { useLocalization } from '../../../../common/providers/LocaleProvider';
import { trackLogoClick } from '../tracking';
var LogoLink = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var BrandLogo = styled.img(compose(width, maxWidth, height, maxHeight));
var brandLogoStyles = {
    src: h24LogoSvg,
    width: H24_IMG_WIDTH,
    maxWidth: H24_IMG_WIDTH,
    height: H24_IMG_HEIGHT,
    maxHeight: H24_IMG_HEIGHT,
};
var Logo = function () {
    var _a = useLocalization(), t = _a.t, prefix = _a.prefix;
    return (React.createElement(LogoLink, { href: prefix(t('h24_home_url')), onClick: function () { return trackLogoClick(); } },
        React.createElement(BrandLogo, __assign({ "data-testid": "brand-logo", alt: t('h24_logo_alt_title') }, brandLogoStyles))));
};
export default Logo;
var templateObject_1;
