var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, display, flexDirection, justifyContent } from 'styled-system';
import { Flex } from '../../../common/components/Flex';
import { Grid, GridItem } from '../../../common/components/Grid';
import { textWithEllipsis } from '../../../common/theme/helper';
import { TrackingEventName, TrackingPlacement } from '../types';
import Link from './common/Link';
import Text from './common/Text';
import Entrypoint from './Entrypoint';
import UrgentMessageUspBar from './UrgentMessageUspBar';
var FIRST_ENTRIES_DISPLAY = ['none', 'none', 'none', 'flex'];
var LAST_ENTRIES_DISPLAY = ['none', 'none', 'flex', 'flex'];
var Container = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
Container.defaultProps = __assign(__assign({}, Grid.defaultProps), { bg: 'sys.neutral.background.strong' });
var MainUspBar = styled(GridItem)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, alignItems, flexDirection, justifyContent);
MainUspBar.defaultProps = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
};
var MainText = styled(Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), textWithEllipsis(1));
var MainLink = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), textWithEllipsis(1));
var EntryPointWrapper = styled(Flex)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), display);
EntryPointWrapper.defaultProps = {
    display: ['none', null, 'flex'],
    justifyContent: 'flex-end',
};
var UspBar = function (_a) {
    var text = _a.text, _b = _a.textUrl, fullUrl = _b.fullUrl, target = _b.target, entryPoints = _a.entryPoints, urgentMessage = _a.urgentMessage;
    var firstEntries = entryPoints.slice(0, -3);
    var lastEntries = entryPoints.slice(-3);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { "data-testid": "usp-bar-container" },
            React.createElement(MainUspBar, null,
                fullUrl ? (React.createElement(MainLink, { href: fullUrl, target: target, tracking: {
                        eventName: TrackingEventName.Click,
                        clickedText: text,
                        placement: TrackingPlacement.UspBar,
                    } }, text)) : (React.createElement(MainText, null, text)),
                React.createElement(EntryPointWrapper, null,
                    firstEntries.map(function (props, index) { return (React.createElement(Entrypoint, __assign({ key: "first-".concat(index), display: FIRST_ENTRIES_DISPLAY }, props))); }),
                    lastEntries.map(function (props, index) { return (React.createElement(Entrypoint, __assign({ key: "last-".concat(index), display: LAST_ENTRIES_DISPLAY }, props))); })))),
        React.createElement(UrgentMessageUspBar, __assign({}, urgentMessage))));
};
export default UspBar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
