var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { backgroundColor, border, borderColor, borderRadius, maxWidth, minHeight, minWidth, position, size, themeGet, } from 'styled-system';
import { MessageType } from '../../enums/Alert';
import { cursor, fill } from '../../theme/system-utilities';
import { Flex } from '../Flex';
import Icon from '../Icon';
import RichText, { font } from '../RichText';
import { Text } from '../Text';
var ICON_SIZES = [24, null, null, 32];
var Container = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  scroll-margin-top: 16px;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  scroll-margin-top: 16px;\n"])), position, backgroundColor, border, borderColor, borderRadius, maxWidth, cursor);
Container.defaultProps = {
    position: 'relative',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: 'medium',
    py: 'sp_8',
    pl: 'sp_8',
    pr: 'sp_16',
    mb: 'sp_8',
};
var StyledIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), size, minWidth, minHeight, fill);
StyledIcon.defaultProps = {
    size: ICON_SIZES,
    minWidth: ICON_SIZES,
    minHeight: ICON_SIZES,
};
var StyledText = styled(Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
StyledText.defaultProps = __assign(__assign({}, Text.defaultProps), { as: 'span', fontSize: ['fs_14', 'fs_16'], lineHeight: ['lh_20', 'lh_24'] });
var AlertRichText = styled(RichText)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n\n  ul,\n  ol {\n    margin: 0;\n\n    > li {\n      padding-left: 0;\n      ", ";\n    }\n  }\n\n  a {\n    color: ", ";\n  }\n"], ["\n  ", ";\n\n  ul,\n  ol {\n    margin: 0;\n\n    > li {\n      padding-left: 0;\n      ", ";\n    }\n  }\n\n  a {\n    color: ", ";\n  }\n"])), font(['fs_14', 'fs_16'], ['lh_20', 'lh_24']), font(['fs_14', 'fs_16'], ['lh_20', 'lh_24']), function (props) { return themeGet("colors.".concat(props.color)); });
export var getAlertBackgroundColor = function (type) {
    switch (type) {
        case MessageType.Success:
            return 'sys.success.background.weak';
        case MessageType.Error:
            return 'sys.error.background.weak';
        case MessageType.Warning:
            return 'sys.warning.background.weak';
        default:
            return 'sys.secondary.background.weak';
    }
};
export var getAlertTextColor = function (type) {
    switch (type) {
        case MessageType.Success:
            return 'sys.success.text.strong';
        case MessageType.Error:
            return 'sys.error.text.strong';
        case MessageType.Warning:
            return 'sys.warning.text.strong';
        default:
            return 'sys.secondary.text.strong';
    }
};
export var getAlertIconColor = function (type) {
    switch (type) {
        case MessageType.Success:
            return 'sys.success.icon.strong';
        case MessageType.Error:
            return 'sys.error.icon.strong';
        case MessageType.Warning:
            return 'sys.warning.icon.strong';
        default:
            return 'sys.secondary.icon.strong';
    }
};
export var getAlertBorderColor = function (type) {
    switch (type) {
        case MessageType.Success:
            return 'sys.success.border.default';
        case MessageType.Error:
            return 'sys.error.border.default';
        case MessageType.Warning:
            return 'sys.warning.border.default';
        default:
            return 'sys.secondary.border.default';
    }
};
var getIconName = function (type) {
    switch (type) {
        case MessageType.Success:
            return 'success';
        case MessageType.Error:
            return 'error';
        case MessageType.Warning:
            return 'warning';
        default:
            return 'info';
    }
};
var Alert = forwardRef(function (_a, ref) {
    var type = _a.type, _b = _a.withIcon, withIcon = _b === void 0 ? true : _b, _c = _a.dataTestId, dataTestId = _c === void 0 ? 'alert' : _c, children = _a.children, className = _a.className, html = _a.html, onClick = _a.onClick, _d = _a.withBorder, withBorder = _d === void 0 ? true : _d, styleProps = __rest(_a, ["type", "withIcon", "dataTestId", "children", "className", "html", "onClick", "withBorder"]);
    var containerBackgroundColor = getAlertBackgroundColor(type);
    var containerBorderColor = getAlertBorderColor(type);
    var textColor = getAlertTextColor(type);
    var iconColor = getAlertIconColor(type);
    var iconName = getIconName(type);
    var styledTextProps = {
        color: textColor,
        ml: withIcon ? 'sp_8' : 'sp_0',
    };
    return (React.createElement(Container, __assign({ className: className, role: "alert", backgroundColor: containerBackgroundColor, borderColor: containerBorderColor, "data-testid": dataTestId, ref: ref, onClick: onClick }, (!!onClick && {
        cursor: 'pointer',
    }), (!withBorder && {
        border: 'none',
    }), styleProps),
        withIcon && React.createElement(StyledIcon, { name: iconName, fill: iconColor }),
        html ? (React.createElement(AlertRichText, __assign({}, styledTextProps, { dangerouslySetInnerHTML: { __html: html } }))) : (React.createElement(StyledText, __assign({}, styledTextProps), children))));
});
Alert.displayName = 'Alert';
export default Alert;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
