var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { height, space, width } from 'styled-system';
import { useAltText } from './hooks/useAltText';
var ICON_SIZE = 16;
var Icon = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), height, width, space);
var iconStyles = {
    height: ICON_SIZE,
    width: ICON_SIZE,
};
var EntryIcon = function (_a) {
    var label = _a.label, url = _a.url, alt = _a.alt;
    var altText = useAltText(alt, label);
    return React.createElement(Icon, __assign({ src: url, alt: altText, mr: label ? 'sp_8' : 'sp_0' }, iconStyles));
};
export default EntryIcon;
var templateObject_1;
