var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { display } from 'styled-system';
import { Grid, GridItem } from '../../../common/components/Grid';
import { TrackingEventName, TrackingPlacement } from '../types';
import Link from './common/Link';
import Text from './common/Text';
var UrgentMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), display);
var urgentMessageWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
};
var UrgentLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
    var textHoverColor = _a.textHoverColor;
    return textHoverColor && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      &:active,\n      &:hover,\n      &:focus {\n        color: ", ";\n        text-decoration-color: ", ";\n      }\n    "], ["\n      &:active,\n      &:hover,\n      &:focus {\n        color: ", ";\n        text-decoration-color: ", ";\n      }\n    "])), textHoverColor, textHoverColor);
});
var UrgentMessageUspBar = function (_a) {
    var text = _a.text, _b = _a.textUrl, fullUrl = _b.fullUrl, target = _b.target, textColor = _a.textColor, textHoverColor = _a.textHoverColor, bgColor = _a.bgColor, visibleOnSmall = _a.visibleOnSmall, visibleOnMedium = _a.visibleOnMedium, visibleOnLarge = _a.visibleOnLarge;
    return (React.createElement(UrgentMessageContainer, { "data-testid": "urgent-message-bar", display: [
            visibleOnSmall ? 'flex' : 'none',
            visibleOnMedium ? 'flex' : 'none',
            visibleOnLarge ? 'flex' : 'none',
            visibleOnLarge ? 'flex' : 'none',
        ] },
        React.createElement(Grid, { bg: bgColor },
            React.createElement(GridItem, __assign({}, urgentMessageWrapperStyles), fullUrl ? (React.createElement(UrgentLink, { href: fullUrl, target: target, color: textColor, textHoverColor: textHoverColor, textDecoration: "underline", tracking: {
                    eventName: TrackingEventName.Click,
                    clickedText: text,
                    placement: TrackingPlacement.UrgentMessageBar,
                } }, text)) : (React.createElement(Text, { color: textColor }, text))))));
};
export default UrgentMessageUspBar;
var templateObject_1, templateObject_2, templateObject_3;
