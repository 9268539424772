import React from 'react';
import { DisclaimerType } from '../../enums/Tracking';
import { useOpenDisclaimer } from '../../hooks/drawer';
import { useLocalization } from '../../providers/LocaleProvider';
import { dispatchTrackingEventGA4 } from '../../tracking/dispatchTrackingEventGA4';
import { DrawerInteraction, Element, TrackingEventName } from '../../types/tracking';
import ZipCodeDrawerContent from './index';
var trackDrawerClose = function (type) {
    return dispatchTrackingEventGA4({
        event: TrackingEventName.CommonDisclaimerClick,
        common_disclaimer_type: type,
        interaction: DrawerInteraction.Close,
        element: Element.Button,
        button_text: 'Close',
    });
};
export var useOpenZipCodeDrawer = function (onUpdate, type) {
    if (type === void 0) { type = DisclaimerType.ZipCodeCartSummary; }
    var t = useLocalization().t;
    var title = t('h24_enter_zip_code_header');
    return useOpenDisclaimer({
        title: title,
        ariaLabelledby: title,
        content: React.createElement(ZipCodeDrawerContent, { onUpdate: onUpdate }),
        closeAction: function () {
            trackDrawerClose(type);
        },
    });
};
