var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Link } from '../../../../../common/components/Link';
import { HOTJAR_SUPPRESS_ATTRS } from '../../../../../common/constants/hotjar';
import { useLocalization } from '../../../../../common/providers/LocaleProvider';
import { getTrackerProps } from '../../../../../common/tracking/getTrackerProps';
var LogoutBlock = function (_a) {
    var _b = _a.not, not = _b === void 0 ? 'h24_not_your_account' : _b, _c = _a.logoutURL, logoutURL = _c === void 0 ? 'h24_logout_url' : _c, _d = _a.firstName, firstName = _d === void 0 ? '' : _d;
    var _e = useLocalization(), r = _e.r, prefix = _e.prefix;
    return (React.createElement(Link, __assign({}, getTrackerProps(__assign(__assign({}, HOTJAR_SUPPRESS_ATTRS), { href: prefix.t(logoutURL), dangerouslySetInnerHTML: {
            __html: r.t(not, {
                username: firstName,
            }),
        } }), {
        event: 'headerTracking',
        eventData: {
            placement: 'userAccountIcon',
            labelIdentifier: not,
            listIndex: 0,
            listLength: 1,
        },
    }))));
};
export default LogoutBlock;
