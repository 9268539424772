var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { alignItems, display, themeGet } from 'styled-system';
import { Button } from '../../../common/components/Button';
import { Flex } from '../../../common/components/Flex';
import Icon from '../../../common/components/Icon';
import { CaptionBoldCB1, CaptionRegularCR1 } from '../../../common/components/Typography';
import { useOpenZipCodeDrawer } from '../../../common/components/ZipCodeDrawer/hooks';
import CookieStorage from '../../../common/CookieStorage';
import { EventBusEvent } from '../../../common/enums/EventBus';
import { DisclaimerType } from '../../../common/enums/Tracking';
import { useSubscriptionCallback } from '../../../common/hooks/subscription';
import { useLocalization } from '../../../common/providers/LocaleProvider';
import { trackZipCodeClick } from './tracking';
var Container = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n\n  &:hover div {\n    color: ", ";\n  }\n  &:active div {\n    color: ", ";\n  }\n"], ["\n  ", "\n  ", "\n\n  &:hover div {\n    color: ", ";\n  }\n  &:active div {\n    color: ", ";\n  }\n"])), display, alignItems, themeGet('colors.sys.primary.text.hover'), themeGet('colors.sys.primary.text.active'));
var containerStyles = {
    kind: 'bare',
    display: 'flex',
    alignItems: 'center',
};
var onZipCodeUpdate = function () {
    // We reload the page so all affected components would re-fetch the data.
    // As the future improvement, components could listen to "ZipCodeUpdate" event
    // and handle the internal update instead.
    window.location.reload();
};
var ZipCode = function () {
    var _a = useLocalization(), t = _a.t, r = _a.r;
    var openZipCodeDrawer = useOpenZipCodeDrawer(onZipCodeUpdate, DisclaimerType.ZipCodeGlobalHeader);
    var _b = __read(useState('-'), 2), zipCode = _b[0], setZipCode = _b[1];
    var zipCodeLabel = zipCode !== null && zipCode !== void 0 ? zipCode : t('h24_enter_zip_code_short');
    // Update the zip code on the client-side, to avoid hydration error.
    // Can be improved by reading the cookie on server and passing down prop from Page app.
    useEffect(function () {
        setZipCode(CookieStorage.getZipCode());
    }, []);
    var handleClick = function () {
        trackZipCodeClick(zipCodeLabel);
        openZipCodeDrawer();
    };
    useSubscriptionCallback(EventBusEvent.ZipCodeUpdate, function (newZipCode) {
        setZipCode(newZipCode);
    });
    return (React.createElement(Container, __assign({ onClick: handleClick }, containerStyles),
        React.createElement(Icon, { name: "pin", size: 32 }),
        React.createElement(Flex, { flexDirection: ['row', null, 'column'], alignItems: "flex-start" },
            React.createElement(CaptionRegularCR1, { mr: ['sp_4', null, 'sp_0'] }, r.t('h24_shipping_to_zip', { zipCode: ' ' })),
            React.createElement(CaptionBoldCB1, null, zipCodeLabel))));
};
export default ZipCode;
var templateObject_1;
