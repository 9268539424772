var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { borderColor, borderRadius, borderStyle, borderWidth, compose, display, size, space, } from 'styled-system';
import { Button } from '../../../../common/components/Button';
import Icon from '../../../../common/components/Icon';
import { fill } from '../../../../common/theme/system-utilities';
import { trackHamburgerClick } from '../tracking';
var ICON_SIZE = 46;
var MenuButtonContainer = styled(Button)(compose(borderColor, borderRadius, borderStyle, borderWidth, display, space));
var menuButtonContainerStyles = {
    kind: 'bare',
    borderColor: 'sys.neutral.border.default',
    borderRadius: 'small',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'inline-flex',
    mr: ['sp_12', null, 'sp_16'],
};
var BurgerIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fill, size);
var burgerIconStyles = {
    fill: 'sys.neutral.icon.active',
    size: ICON_SIZE,
};
var MenuButton = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement(MenuButtonContainer, __assign({ "data-testid": "hamburger-button", "aria-label": "menu", onClick: function () {
            trackHamburgerClick();
            onClick();
        } }, menuButtonContainerStyles),
        React.createElement(BurgerIcon, __assign({ name: "burger", dataTestId: "hamburger-button-icon", ariaHidden: true }, burgerIconStyles))));
};
export default MenuButton;
var templateObject_1;
