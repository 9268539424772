var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { ButtonLink } from '../../../../../common/components/Button';
import { Link } from '../../../../../common/components/Link';
import { HOTJAR_SUPPRESS_ATTRS } from '../../../../../common/constants/hotjar';
import { useLocalization } from '../../../../../common/providers/LocaleProvider';
import { font } from '../../../../../common/theme/helper';
import { Element } from '../../../../../common/types/tracking';
import { trackMyAccountTooltipClick } from '../../tracking';
var UpperBlockContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var AuthorisedUpperText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), font(2));
var AuthorisedUpperBlock = function (_a) {
    var fullName = _a.fullName;
    var t = useLocalization().t;
    return (React.createElement(AuthorisedUpperText, __assign({}, HOTJAR_SUPPRESS_ATTRS),
        t('h24_hello'),
        " ",
        fullName));
};
var NewCustomerContainer = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n\n  margin: 0;\n  margin-top: 8px;\n"], ["\n  ", ";\n\n  margin: 0;\n  margin-top: 8px;\n"])), font(2));
var UnauthorisedUpperBlock = function (_a) {
    var _b = useLocalization(), t = _b.t, prefix = _b.prefix;
    var loginLabel = t('h24_register');
    var newCustomerLabel = t('h24_new_customer_?');
    var registerNowLabel = t('h24_register_now');
    return (React.createElement(UpperBlockContainer, null,
        React.createElement(ButtonLink, { href: prefix.t('h24_login_page_url'), onClick: function () { return trackMyAccountTooltipClick(loginLabel, Element.Button); } }, loginLabel),
        React.createElement(NewCustomerContainer, null,
            React.createElement(Link, { href: prefix.t('h24_register_url'), onClick: function () { return trackMyAccountTooltipClick(registerNowLabel); } },
                newCustomerLabel,
                " ",
                registerNowLabel))));
};
var UpperBlock = function (_a) {
    var customer = _a.customer;
    return (customer === null || customer === void 0 ? void 0 : customer.isLoggedIn) ? (React.createElement(AuthorisedUpperBlock, { fullName: customer.fullName })) : (React.createElement(UnauthorisedUpperBlock, null));
};
export default UpperBlock;
var templateObject_1, templateObject_2, templateObject_3;
