var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, boxShadow } from 'styled-system';
import { Flex, FlexItem } from '../../../common/components/Flex';
import Search from '../../../common/components/Search/Search';
import { useMatchesViewport } from '../../../common/hooks/media';
import { useLocalization } from '../../../common/providers/LocaleProvider';
import { openSideNav } from '../../../common/utils/navigation';
import LanguageSwitch from './LanguageSwitch';
import Logo from './Logo/Logo';
import MenuButton from './MenuButton/MenuButton';
import ToolbarActionItem from './ToolbarActionItem';
import { getActionItems } from './utils';
import ZipCode from './ZipCode';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), backgroundColor, boxShadow);
var wrapperStyles = {
    backgroundColor: 'sys.neutral.background.default',
};
var containerStyles = {
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: ['wrap', null, 'nowrap'],
    maxWidth: 1600,
    mx: 'auto',
    py: ['sp_16', 'sp_12'],
    px: ['sp_16', 'sp_24', 'sp_48', 'sp_64'],
};
var searchContainerStyles = {
    width: [1, 'auto'],
    order: [1, 0],
    flex: ['none', 1],
    maxWidth: [null, null, null, '920px'],
    mx: ['sp_0', 'sp_16', 'sp_20'],
};
var actionItemsStyles = {
    display: 'flex',
    justifyContent: 'flex-end',
};
var zipCodeContainerStyles = {
    width: [1, null, 'auto'],
    order: [2, 1, 0],
    flexShrink: 0,
    mt: ['sp_12', null, 'sp_0'],
    ml: ['sp_0', null, 'sp_16'],
};
var Toolbar = function (_a) {
    var customer = _a.customer, searchParams = _a.searchParams, _b = _a.searchOptions, showIconOnMobile = _b.showIconOnMobile, _c = _b.withInputFilled, withInputFilled = _c === void 0 ? true : _c, restProps = __rest(_a, ["customer", "searchParams", "searchOptions"]);
    var getLanguages = useLocalization().getLanguages;
    var isLoggedIn = !!(customer === null || customer === void 0 ? void 0 : customer.isLoggedIn);
    var actionItemsProps = __assign({ customer: customer }, restProps);
    var actionItems = getActionItems(actionItemsProps);
    var matchesTablet = useMatchesViewport(false).matches.matchesTablet;
    var showOnlySearchIcon = !matchesTablet && showIconOnMobile;
    var searchProps = {
        params: searchParams,
        withInputFilled: withInputFilled,
    };
    return (React.createElement(Wrapper, __assign({}, wrapperStyles, (!showOnlySearchIcon && { boxShadow: 'bottom' })),
        React.createElement(Flex, __assign({ "data-testid": "toolbar" }, containerStyles),
            React.createElement(Flex, { alignItems: "center" },
                React.createElement(MenuButton, { onClick: function () { return openSideNav(); } }),
                React.createElement(Logo, null)),
            React.createElement(FlexItem, __assign({}, zipCodeContainerStyles),
                React.createElement(ZipCode, null)),
            React.createElement(FlexItem, __assign({}, searchContainerStyles), !showOnlySearchIcon && React.createElement(Search, __assign({}, searchProps))),
            React.createElement(FlexItem, __assign({}, actionItemsStyles),
                showOnlySearchIcon && React.createElement(Search, __assign({}, searchProps, { showOnlySearchIcon: true })),
                actionItems
                    .filter(function (_a) {
                    var auth = _a.auth, id = _a.id;
                    return (typeof auth === 'undefined' || auth === isLoggedIn) &&
                        !(showOnlySearchIcon && id === 'userAccount');
                })
                    .map(function (item) { return (React.createElement(ToolbarActionItem, __assign({}, item, { key: item.id, isNewIcon: showOnlySearchIcon }))); }),
                React.createElement(LanguageSwitch, { languages: getLanguages() })))));
};
export default Toolbar;
var templateObject_1;
